@import "../../../variable.scss";
.rownd-email-verified-page{
    background: white;
    height: 100%;
    .rownd-login-page-main{
        .rownd-login-details{
            .rownd-email-verified-text{
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                color: $theme-color-02;
                font-weight: normal;
                margin-bottom: 10px;
                img{
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                    object-position: center;
                    margin-right: 10px;
                }
            }
        }
        .password-error{
            .password-wrong
            , .password-check{
                display: inline-flex;
                align-items: center;
                margin-bottom: 6px;
                img{
                    width: 15px;
                    height: auto;
                    margin-right: 12px;
                }
            }
        }
        .rownd-error-include{
            padding-left: 0;
            &::before{
                content: unset;
            }
        }
        .different-email{
          color: #858990;
          margin-left: 15px;
        }
    }
    .signup-details-block {
      background: #F7F7F7;
      .row {
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 10px 0px;
      }
      .image-block{
        display: block;
        position: relative;
        img {
          margin: auto;
          display: block;
          height: 75px;
          width: auto;
        }
      }
      .icon-box-title {
        color: #3E3E3E;
        font-size: 20px;
        font-weight: 600;
      }
      .icon-box-text {
        color: #3E3E3E;
        font-size: 18px;
        font-weight: 400;
      }
    }
}